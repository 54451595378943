import request from '@/utils/request'

export function list(params) {
  return request({
    url: '/norms',
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `/norms/${params.id}`,
    method: 'get',
    params,
  })
}
