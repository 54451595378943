<template>
  <div class="container">
    <nav-bar :title="info.name || ''"></nav-bar>
    <div class="margin">
      <h3 class="w100 center margin-bottom">{{ info.name || '' }}</h3>
      <div v-html="info.body" class="rich-text"></div>
    </div>
  </div>
</template>

<script>
import * as normApi from '@/api/norm'

export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    normApi.show({ id: this.$route.params.id }).then(res => {
      this.info = res.data

      const keyword = this.$route.params.keyword
      if (keyword) {
        const replaceReg = new RegExp(keyword, 'g')
        // 高亮替换v-html值
        const replaceString = `<span class="color-primary">${keyword}</span>`
        this.info.body = this.info.body.replace(replaceReg, replaceString)
      }
    })
  },
  methods: {},
}
</script>
<style lang="stylus" scoped></style>
